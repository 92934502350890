import React from 'react';
import { Container } from 'react-bootstrap';
import { useLiveQuery } from "dexie-react-hooks";
import db from '../../db';
import ProjectList from '../../components/ProjectList/ProjectList';

const ExistingProjects = () => {
  const projects = useLiveQuery(() => db.projects.toArray());

  if (!projects) return null;

  return (
    <Container as="main" className="main">
      <h1 className="mb-3 text-center">Existing Projects</h1>
      <ProjectList projects={projects} />
    </Container>
  );
};

export default ExistingProjects;
