import React from 'react';
import { Container, ListGroup, Row, Col } from 'react-bootstrap';
import SectionListItem from '../../components/SectionList/SectionListItem';

const SectionList = ({ project_id, sections, deactivateSingleCounter }) => {
  const sectionListItems = sections?.map(section => <SectionListItem key={section.id} project_id={project_id} section={section} deactivateSingleCounter={deactivateSingleCounter} />);

  return (
    <Container className="list mb-4">
      <ListGroup as="ul">
        <ListGroup.Item as="li" key={0} className="list--list-header d-none d-md-block">
          <Container fluid>
            <Row>
              <Col xs={12} md={3} lg={5}><span className="fw-bold">Section</span></Col>
              <Col xs={6} md={2} lg={1}><span className="fw-bold">Current Row</span></Col>
              <Col xs={5} md={2} lg={1}><span className="fw-bold">Total Rows</span></Col>
              <Col xs={1}><span className="fw-bold">Active?</span></Col>
              <Col xs={12} md={4}><span className="fw-bold">Actions</span></Col>
            </Row>
          </Container>
        </ListGroup.Item>
        {sectionListItems}
      </ListGroup>
    </Container>
  );
};

export default SectionList;
