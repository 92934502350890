import React from 'react';
import { Container, Button } from 'react-bootstrap';

const Home = () => {
  return (
    <Container as="main" className="main">
      <div className="about">
        <h1 className="about--title text-center">About Us</h1>
        <div className="text-center mt-3">
          <p>
            Welcome! I don't know what to put here yet, so maybe
            <Button className="home--tutorial-button mx-1" href="/tutorial" variant="outline-primary">check out the tutorial</Button>
            or
            <Button className="home--tutorial-button mx-1" href="/projects/new" variant="primary">add a new project</Button>.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Home;
