import React from 'react';
import { Image } from 'react-bootstrap';

const TutorialStep = ({ src, alt }) => {
  return (
    <Image src={src} alt={alt} width="100%" />
  );
};

export default TutorialStep;
