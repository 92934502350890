import React from 'react';
import { ListGroup, Container, Row, Col } from 'react-bootstrap';

const PatternListItem = ({ project_id, section_id, pattern }) => {
  return (
    <ListGroup.Item as="li" className="list--list-item">
      <Container fluid>
        <Row>
          <Row>
            <Col xs={12}>
              <h5>{pattern.name}</h5>
              <div className="fst-italic">{pattern.description}</div>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <span className="d-inline fw-bold">Current row: </span>{pattern.current_row}
            </Col>
            <Col xs={6}>
              <span className="d-inline fw-bold">Total rows: </span>{pattern.total_rows}
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <span className="d-inline fw-bold">Done repeats: </span>{pattern.completed_repeats}
            </Col>
            <Col xs={6}>
              <span className="d-inline fw-bold">Total repeats: </span>{pattern.total_repeats}
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <span className="d-inline fw-bold">Active: </span>{pattern.active && (<React.Fragment>✔</React.Fragment>)}
            </Col>
            <Col xs={6}>
              <span className="d-inline fw-bold">Auto-Deactivate: </span>{pattern.auto_deactivate && (<React.Fragment>✔</React.Fragment>)}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ListGroup horizontal className="pattern--action-items">
                <ListGroup.Item action href={`/projects/${project_id}/sections/${section_id}/patterns/${pattern.id}/edit`} className="pattern--action-item">Edit</ListGroup.Item>
                <ListGroup.Item action href={`/projects/${project_id}/sections/${section_id}/patterns/${pattern.id}/delete`} className="pattern--action-item">Delete</ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Row>
      </Container>
    </ListGroup.Item>
  );
};

export default PatternListItem;
