import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './containers/Home/Home';
import ExistingProjects from './containers/ExistingProjects/ExistingProjects';
import NewProject from './containers/NewProject/NewProject';
import ExistingProject from './containers/ExistingProject/ExistingProject';
import ExistingProjectEdit from './containers/ExistingProject/ExistingProjectEdit';
import ExistingProjectDelete from './containers/ExistingProject/ExistingProjectDelete';
import NewSection from './containers/NewSection/NewSection';
import ExistingSectionEdit from './containers/ExistingSection/ExistingSectionEdit';
import ExistingSectionDelete from './containers/ExistingSection/ExistingSectionDelete';
import NewPattern from './containers/NewPattern/NewPattern';
import ExistingPatternEdit from './containers/ExistingPattern/ExistingPatternEdit';
import ExistingPatternDelete from './containers/ExistingPattern/ExistingPatternDelete';
import PrivacyPolicy from './containers/Policies/PrivacyPolicy';
import Tutorial from './containers/Tutorial/Tutorial';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/projects' exact element={<ExistingProjects />} />
        <Route path='/projects/new' exact element={<NewProject />} />
        <Route path='/projects/:project_id' exact element={<ExistingProject />} />
        <Route path='/projects/:project_id/edit' exact element={<ExistingProjectEdit />} />
        <Route path='/projects/:project_id/delete' exact element={<ExistingProjectDelete />} />
        <Route path='/projects/:project_id/sections/new' exact element={<NewSection />} />
        <Route path='/projects/:project_id/sections/:section_id/edit' exact element={<ExistingSectionEdit />} />
        <Route path='/projects/:project_id/sections/:section_id/delete' exact element={<ExistingSectionDelete />} />
        <Route path='/projects/:project_id/sections/:section_id/patterns/new' exact element={<NewPattern />} />
        <Route path='/projects/:project_id/sections/:section_id/patterns/:pattern_id/edit' exact element={<ExistingPatternEdit />} />
        <Route path='/projects/:project_id/sections/:section_id/patterns/:pattern_id/delete' exact element={<ExistingPatternDelete />} />
        <Route path='/tutorial' exact element={<Tutorial />} />
        <Route path='/privacy-policy' exact element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
