import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useLiveQuery } from "dexie-react-hooks";
import db from '../../db';
import Project from '../../components/Project/Project';

const ExistingProject = () => {
  const { project_id } = useParams();
  const project = useLiveQuery(() => db.projects.where("id").equals(parseInt(project_id)).toArray());

  if (!project) return null;

  return (
    <Container as="main" className="main">
      <Project project_id={parseInt(project_id)} project={project[0]} />
    </Container>
  );
};

export default ExistingProject;
