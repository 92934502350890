import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useLiveQuery } from "dexie-react-hooks";
import db from '../../db';
import SectionForm from '../../components/Forms/SectionForm';

const NewSection = () => {
  const { project_id } = useParams();
  const project = useLiveQuery(() => db.projects.where("id").equals(parseInt(project_id)).toArray());

  if (!project) return null;

  return (
    <Container as="main" className="main">
      <SectionForm isNew={true} project={project[0]} />
    </Container>
  );
};

export default NewSection;
