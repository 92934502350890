import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useLiveQuery } from "dexie-react-hooks";
import db from '../../db';

const ExistingProjectDelete = () => {
  const navigate = useNavigate();

  const [error, setError] = useState();

  const { project_id } = useParams();
  const project = useLiveQuery(() => db.projects.where("id").equals(parseInt(project_id)).toArray());
  const sections = useLiveQuery(() => db.sections.where("project_id").equals(parseInt(project_id)).toArray());

  const handleClick = async () => {
    try {
      sections?.map(section => {
        db.patterns.where("section_id").equals(section.id).delete().then(deleteCount => {
          if (!deleteCount) setError(`Something went wrong. Patterns associated with the ${section.name} section may not have been deleted.`);
        });

        return null;
      });

      db.sections.where("project_id").equals(project[0].id).delete().then(deleteCount => {
        if (!deleteCount) setError("Something went wrong. Sections associated with this project may not have been deleted.");
      });

      db.projects.where("id").equals(project[0].id).delete().then(deleteCount => {
        if (deleteCount) navigate("/projects");
        if (!deleteCount) setError('Something went wrong. Your project may not have been deleted.');
      });
    }
    catch (error) {
      console.log(error);
      setError('Something went wrong. Your project may not have been deleted.');
    }
  }

  if (!project) return null;
  if (!sections) return null;

  return (
    <Container as="main" className="main">
      <Container className="confirmation--delete mb-3">
        {error && <div className="mb-3 alert alert-danger">{error}</div>}
        <div className="confirmation--text mb-3">
          <h1>Delete {project[0].name}?</h1>
          Are you sure you want to delete your project <span className="fw-bold">{project[0].name}</span>? This will also delete all associated data (row counts, sections, patterns)!
        </div>
        <div className="confirmation--actions mb-3">
          <Button variant="primary" onClick={handleClick} className="actions--delete">
            Delete Project
          </Button>

          <Link to="/projects">
            <Button variant="secondary" className="actions--cancel">
              Go Back
            </Button>
          </Link>
        </div>
      </Container>
    </Container>
  );
};

export default ExistingProjectDelete;
