import React from 'react';
import { Container, ListGroup, } from 'react-bootstrap';
import PatternListItem from '../../components/PatternList/PatternListItem';

const PatternList = ({ project_id, section_id, patterns }) => {
  const patternListItems = patterns?.map(pattern => <PatternListItem key={pattern.id} project_id={project_id} section_id={section_id} pattern={pattern} />);

  return (
    <Container className="list mb-4">
      <ListGroup as="ul">
        {patternListItems}
      </ListGroup>
    </Container>
  );
};

export default PatternList;
