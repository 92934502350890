import React, { useState, useEffect, useRef } from 'react';
import { Container, Button } from 'react-bootstrap';
import db from '../../db';

const Counter = ({ counter_type, counter_id, current_row, total_rows, show_button, projectCurrentRow, handleDeactivate, setCurrentProjectRow, setSuccessMessage }) => {
  const ref = useRef(null);
  const [counter, setCounter] = useState(current_row);
  const [error, setError] = useState();
  const firstUpdate = useRef(true);

  let db_table = null;

  switch (counter_type) {
    case "projects":
      db_table = db.projects;
      break;
    case "sections":
      db_table = db.sections;
      break;
    case "patterns":
      db_table = db.patterns;
      break;
    default:
      break;
  }

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (projectCurrentRow) {
      setCounter(counter + 1);
    }
  }, [projectCurrentRow])

  useEffect(() => {
    setSuccessMessage();
  }, [counter]);

  useEffect(() => {
    try {
      db_table.update(counter_id, { current_row: counter }).then(updated => {
        if (updated && setCurrentProjectRow) setCurrentProjectRow(counter);
        if (!updated) setError('Something went wrong. Your updated row count may not have been saved.');
      });
    }
    catch (error) {
      console.log(error);
      setError('Something went wrong. Your updated row count may not have been saved.');
    }
  }, [counter]);

  const handleClick = () => {
    if (setCurrentProjectRow) {
      setError();
      setCounter(counter + 1);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === " " && document.activeElement !== ref.current) {
      if (setCurrentProjectRow) {
        setError();
        setCounter(counter + 1);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
  }, [handleKeyDown] );

  useEffect(() => {
    if (projectCurrentRow && total_rows > 0 && counter === total_rows) {
      console.log("Total rows: " + total_rows);
      console.log("Current row: " + counter);
      handleDeactivate(setCounter);
    }
  }, [counter, total_rows, handleDeactivate]);

  return (
    <Container className="counter text-center">
      {error && (<div className="mb-3 alert alert-danger">{error}</div>)}
      <span className="counter--current px-2">{counter}</span> {total_rows > 0 && (<React.Fragment>of <span className="counter--total px-2">{total_rows}</span></React.Fragment>)}
      {show_button && (
        <div className="counter--button">
          <Button variant="primary" onClick={handleClick} ref={ref}>
            Increment
          </Button>
        </div>
      )}
    </Container>
  );
};

export default Counter;
