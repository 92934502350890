import React from 'react';
import { Container } from 'react-bootstrap';
import ProjectForm from '../../components/Forms/ProjectForm';

const NewProject = () => {
  return (
    <Container as="main" className="main">
      <ProjectForm isNew={true} />
    </Container>
  );
};

export default NewProject;
