import React from 'react';
import { ListGroup, Container, Row, Col } from 'react-bootstrap';
import { useLiveQuery } from "dexie-react-hooks";
import db from '../../db';
import PatternList from '../../components/PatternList/PatternList';

const SectionListItem = ({ project_id, section, deactivateSingleCounter }) => {
  const patterns = useLiveQuery(() => db.patterns.where("section_id").equals(section.id).toArray(), [deactivateSingleCounter]);

  if (!patterns) return null;

  return (
    <ListGroup.Item as="li" className="list--list-item">
      <Container fluid>
        <Row>
          <Col xs={12} md={3} lg={5}>
            <h5>{section.name}</h5>
            <div className="fst-italic">{section.description}</div>
            {patterns.length > 0 && (
              <Container fluid className="list--patterns">
                <h6>Patterns</h6>
                <PatternList project_id={project_id} section_id={section.id} patterns={patterns} />
              </Container>
            )}
          </Col>
          <Col xs={6} md={2} lg={1}>
            <span className="d-inline d-md-none fw-bold">Current row: </span>{section.current_row}
          </Col>
          <Col xs={5} md={2} lg={1}>
            <span className="d-inline d-md-none fw-bold">Total rows: </span>{section.total_rows}
          </Col>
          <Col xs={1} md={2} lg={1}>
            <span className="d-inline d-md-none fw-bold">Active: </span>{section.active && (<React.Fragment>✔</React.Fragment>)}
          </Col>
          <Col xs={12} md={4}>
            <ListGroup horizontal className="section--action-items">
              <ListGroup.Item action href={`/projects/${project_id}/sections/${section.id}/patterns/new`} className="section--action-item">New Pattern</ListGroup.Item>
              <ListGroup.Item action href={`/projects/${project_id}/sections/${section.id}/edit`} className="section--action-item">Edit</ListGroup.Item>
              <ListGroup.Item action href={`/projects/${project_id}/sections/${section.id}/delete`} className="section--action-item">Delete</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </ListGroup.Item>
  );
};

export default SectionListItem;
