import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useLiveQuery } from "dexie-react-hooks";
import db from '../../db';
import PatternForm from '../../components/Forms/PatternForm';

const NewPattern = () => {
  const { section_id } = useParams();
  const section = useLiveQuery(() => db.sections.where("id").equals(parseInt(section_id)).toArray());

  if (!section) return null;

  return (
    <Container as="main" className="main">
      <PatternForm isNew={true} section={section[0]} />
    </Container>
  );
};

export default NewPattern;
