import React, { useState } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import db from '../../db';

const UserButtons = () => {
  const [show, setShow] = useState(false);
  const [modalText, setModalText] = useState("Are you sure you want to delete all of your data? This action can not be reversed.");
  const [showDeleteButton, setShowDeleteButton] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = () => {
    setShowDeleteButton(false);

    try {
      db.delete().then(() => {
        setModalText("All data has been deleted. Refresh the page to add new projects.");
      });
    }
    catch (e) {
      console.log(e);
      setShowDeleteButton(true);
      setModalText("Something went wrong! You need to manually delete the database from local storage.");
    }
  }

  return (
    <Container className="mb-3">
      <Button clasName="m-auto" variant="danger" siz="lg" onClick={handleShow}>Delete Your Data</Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Your Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {showDeleteButton && (<Button variant="danger" onClick={handleClick}>
            Delete All Data
          </Button>)}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserButtons;
