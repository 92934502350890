import React from 'react';
import { Container, Navbar, Row } from 'react-bootstrap';
import GlobalNav from '../GlobalNav/GlobalNav';
import { ReactComponent as Title } from '../../assets/tkc_title.svg';

const Header = () => {
  return (
    <Container as="header" className="header border-bottom border-light">
      <div className="header--center mx-auto">
        <Row className="header--title">
          <Navbar.Brand href="/">
            <Title alt="The Knitting Counter" width="auto" height="auto" />
          </Navbar.Brand>
        </Row>
        <Row className="header--nav">
          <GlobalNav />
        </Row>
      </div>
    </Container>
  );
};

export default Header;
