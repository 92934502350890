import React, { useState, useEffect } from 'react';
import { Container, Form, Button, FloatingLabel, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from "yup";
import db from '../../db';

const ProjectForm = ({ isNew, project }) => {
  const projectSchema = Yup.object().shape({
    name: Yup.string().required("Name is required.").test("name-unique", "Name needs to be unique.", async (values, ctx) => {
      if (isNew && typeof values != 'undefined') {
        const sameName = await db.projects.where({ name: values }).count(total => {
          return total;
        });
        return sameName === 0;
      }

      return true;
    }),
    description: Yup.string("Description must be text."),
    current_row: Yup.number("Current row must be a number.").integer("Current row must be a whole number.").min(0, "Current row must be 0 or greater."),
    total_rows: Yup.number("Total rows must be a number.").integer("Total rows must be a whole number.").min(0, "Total rows must be 0 or greater.")
  });

  const [projectValues, setProjectValues] = useState({
    name: project && project.name ? project.name : '',
    description: project && project.description ? project.description : '',
    current_row: project && project.current_row ? project.current_row : 0,
    total_rows: project && project.total_rows ? project.total_rows : 0
  });

  const [formStatus, setFormStatus] = useState();

  const [initialRun, setInitialRun] = useState(true);

  let success_message = projectValues.name + ' saved!';
  const danger_message = 'Something went wrong. Your project may not have been saved.';

  useEffect(() => {
    const updateDb = async () => {
      try {
        if (isNew) {
          db.projects.add(projectValues).then(updated => {
            if (updated) setFormStatus({ type: "success", message: success_message, project_id: updated });
            if (!updated) setFormStatus({ type: "danger", message: danger_message });
          });;
        }
        else {
          db.projects.update(project.id, projectValues).then(updated => {
            if (updated) setFormStatus({ type: "success", message: success_message, project_id: project.id });
            if (!updated) setFormStatus({ type: "danger", message: danger_message });
          });;
        }
      }
      catch (error) {
        console.log(error);
        setFormStatus({ type: "danger", message: danger_message });
      }
    }

    // Only want to update if not the first update (ie: when things are initially set).
    if (!initialRun) updateDb();
  }, [projectValues, isNew, project, initialRun]);

  const handleSubmit = async (values, { resetForm }) => {
    let newProjectValues = {
      name: values.name,
      description: values.description,
      current_row: values.current_row,
      total_rows: values.total_rows
    };

    success_message = newProjectValues.name + ' saved!';

    setProjectValues(newProjectValues);
    setInitialRun(false);

    if (isNew) resetForm();
  }

  return (
    <Container className="form">
      {formStatus && (
        <Row className="mb-3">
          <Form.Group className={`mb-3 alert alert-${formStatus.type}`}>
            {formStatus.message} {formStatus.project_id && (<a href={`/projects/${formStatus.project_id}`} className="alert-link">Go to current project.</a>)}
          </Form.Group>
        </Row>
      )}
      <Formik
        initialValues={projectValues}
        validationSchema={projectSchema}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Container className="form--project mb-3">
            <h1 className="mb-3 text-center">{isNew && ("New")}{!isNew && (`Edit ${project.name}`)} Project</h1>
            <Form noValidate onSubmit={formik.handleSubmit} aria-label="project-form">
              <Row className="mb-3">
                <Col md={8}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Project Name" controlId="name" >
                      <Form.Control
                        type="text"
                        size="lg"
                        placeholder="Project name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.name}
                        disabled={formik.isSubmitting}
                        value={formik.values.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.name}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Description" controlId="description" >
                      <Form.Control
                        as="textarea"
                        style={{ height: '100px' }}
                        placeholder="Project description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.description}
                        disabled={formik.isSubmitting}
                        value={formik.values.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.description}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Current Row" controlId="current_row" >
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="0"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.current_row}
                        disabled={formik.isSubmitting}
                        value={formik.values.current_row}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.current_row}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Total Rows" controlId="total_rows" >
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="0"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.total_rows}
                        disabled={formik.isSubmitting}
                        value={formik.values.total_rows}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.total_rows}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="primary" type="submit" disabled={formik.isSubmitting || formik.errors.length}>
                Save Project
              </Button>
            </Form>
          </Container>
        )}
      </Formik>
    </Container>
  );
};

export default ProjectForm;
