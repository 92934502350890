import React from 'react';
import { Container, ListGroup, Row, Col } from 'react-bootstrap';
import ProjectListItem from '../../components/ProjectList/ProjectListItem';

const ProjectList = ({ projects }) => {
  if (projects.length === 0) return (
    <Container className="list mb-4">
      No projects exist yet.
    </Container>
  );

  const projectListItems = projects?.map(project => <ProjectListItem project={project} />);

  return (
    <Container className="list mb-4">
      <ListGroup as="ul">
        <ListGroup.Item as="li" key={0} className="list--list-header d-none d-md-block">
          <Container fluid>
            <Row>
              <Col xs={12} md={4} lg={6}><span className="fw-bold">Project</span></Col>
              <Col xs={6} md={2} lg={1}><span className="fw-bold">Current Row</span></Col>
              <Col xs={6} md={2} lg={1}><span className="fw-bold">Total Rows</span></Col>
              <Col xs={12} md={4}><span className="fw-bold">Actions</span></Col>
            </Row>
          </Container>
        </ListGroup.Item>
        {projectListItems}
      </ListGroup>
    </Container>
  );
};

export default ProjectList;
