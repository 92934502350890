import Dexie from 'dexie';

const db = new Dexie('theKnittingCounter');

db.version(1).stores({
  projects: "++id, &name",
  sections: "++id, project_id, name",
  patterns: "++id, section_id, name",
});

export default db
