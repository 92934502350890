import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserButtons from '../UserButtons/UserButtons';
import { ReactComponent as Logo } from '../../assets/tkc_logo.svg';

const Footer = () => {
  return (
    <Container as="footer" className="footer border-top border-light position-relative py-4">
      <div className="footer--logo mb-3 text-center">
        <Navbar.Brand href="/">
          <Logo alt="TKC" width="100px" height="100px" />
        </Navbar.Brand>
      </div>
      <div className="footer--center text-center">
        <div className="footer--delete-all text-center">
          <UserButtons />
        </div>
        <div className="footer--copyright">
          &copy; {new Date().getFullYear()} Two is For Joy | <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
