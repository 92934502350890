import React from 'react';
import { useLocation } from 'react-router-dom';

import { Nav, Navbar } from 'react-bootstrap';

const GlobalNav = () => {
  const location = useLocation();

  return (
    <Navbar expand="sm" className="justify-content-end">
      <Navbar.Toggle className="nav--toggle" aria-controls="mainNav" />
      <Navbar.Collapse id="mainNav" className="nav--main">
        <Nav activeKey={location.pathname} as="nav" className="nav-justified justify-content-center align-items-end">
          <Nav.Link href="/projects/new">New Project</Nav.Link>
          <Nav.Link href="/projects">Existing Projects</Nav.Link>
          <Nav.Link href="/tutorial">Tutorial</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default GlobalNav;
