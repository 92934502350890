import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TutorialStep from '../../components/Tutorial/TutorialStep';
import step01 from '../../assets/tutorial/01-homepage.png';
import step02 from '../../assets/tutorial/02-new_project.png';
import step03 from '../../assets/tutorial/03-new_project_message.png';
import step04 from '../../assets/tutorial/04-project.png';
import step06 from '../../assets/tutorial/06-delete.png';
import step07 from '../../assets/tutorial/07-new_section.png';
import step08 from '../../assets/tutorial/08-new_section_inactive.png';
import step09 from '../../assets/tutorial/09-section_list.png';
import step10 from '../../assets/tutorial/10-new_pattern.png';
import step11 from '../../assets/tutorial/11-section_list.png';
import step12 from '../../assets/tutorial/12-project.png';
import step13 from '../../assets/tutorial/13-finished_pattern.png';
import step14 from '../../assets/tutorial/14-finished_section.png';
import step15 from '../../assets/tutorial/15-existing_projects.png';

const Tutorial = () => {
  return (
    <Container as="main" className="main">
      <h1 className="text-center">Tutorial</h1>
      <div className="text-center mb-5">Knitters can have a little tutorial, as a treat.</div>
      <ul className="tutorial">
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step01} alt="Homepage header" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              Welcome to The Knitting Counter! Let's set up a project to track. <span className="fw-bold">Click on "New Project" in the menu.</span>
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step02} alt="New project page" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              This brings you to the New Project page. The only optional field on this page is the "Description" field. While the example shows the "Total Rows" field set to 100, you can create a project an unkown total number of rows by leaving it set to 0. <span className="fw-bold">Click "Save Project" to save the project.</span>
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step03} alt="New project success message" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              The form reloads with a success or failure message. The success message contains a link to the created/edited project. <span className="fw-bold">Click the "Go to current project" link in the success message.</span>
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step04} alt="Basic project page with no sections" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              Your newly created project consists of a row counter and nothing else. Under the name of the project are three links: "New Section", "Edit", and "Delete". At the bottom is the button to count a row, labeled "Increment". You can also increment the counters by hitting the spacebar. You now have options:
              <ul>
                <li>Do nothing: If you're tracking a fairly simple project, you can start knitting and just click the "Increment" button whenever you finish a row.</li>
                <li>Edit your project: Clicking the "Edit" link takes you to a page that's basically the same as the New Project page, with all the current values filled out for you.</li>
                <li>Delete your project: If you don't want this project anymore, you can delete it and all data associated with it. Go to the next step to see the Delete page, but don't click on the "Delete" link! You haven't finished creating a complex project yet!</li>
                <li>Add a new section: This link goes to a page where you can add a new section to your project. Sections are things like a shawl body, shawl border, sweater arm, etc. You need at least one section if you want to add any repeating patterns to your project. <span className="fw-bold">Click the "New Section" link.</span></li>
              </ul>
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step06} alt="Delete page" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              This is the Delete page. Clicking "Delete Project" will delete all row counts, sections, patterns, and the project itself from your browser. They're gone forever and can't be recovered. If you got to this page by mistake or changed your mind, just click "Go Back" to return to where you were.
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step07} alt="New section page" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              The New Section page has similar fields to the New Project page with some additions. The "Active?" field being checked off means the section will show up and rows will be counted for it. If it's not checked off, it will not show up on the page as a counter and the Current Row will not be changed. "Auto-deactive" will disabled the section when the Current Row is the same as the Total Rows. If you don't check off Auto-deactive, the section counter will continue increasing past the number of total rows. <span className="fw-bold">Click "Save Section" to save the section.</span>
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step08} alt="New section page for inactive section" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              After saving the new section, the form reloads and you can add another. If "Active?" is not checked off, you will need to edit the section later when you want to start tracking row counts toward that section. <span className="fw-bold">Click the "Go to current project" link in the success message.</span>
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step09} alt="Section list with no patterns" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              The list of sections and patterns shows at the bottom of the project page after creating a section. Again, you can stop here if you don't want to track anything else. The "Edit" and "Delete" links are similar to the Project ones. This list is where you add repeating patterns to the section. <span className="fw-bold">Click the "New Pattern" link on a section.</span>
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step10} alt="New pattern page" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              The New Pattern page is similar to the New Section page, with additional fields to track the repeats of the pattern. Whenever the counter for the pattern reaches the point where Current Row is the same as Total Rows, it resets Current Row to 0 and marks that a repeat has been finished. When the number of Completed Repeats is the same as the Total Repeats, the pattern will be auto-deactivated if that option has been checked off. <span className="fw-bold">Click "Save Pattern" to save the pattern.</span>
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step11} alt="Section list with patterns" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              If you want to perform any actions on a pattern, the list of sectins and patterns at the bottom of the project page is where to go. Here you have links that take you to the pattern's Edit or Delete page.
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step12} alt="Project page with multiple counters" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              This is what the page looks like with multiple patterns. Clicking increment/hitting the spacebar will add 1 row to all counters.
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step13} alt="Finished pattern message" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              When a pattern is auto-deactivated, you get this message and the pattern disappears from the ones that are active on the page.
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step14} alt="Finshed section message" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              When a section is auto-deactivated, you get this message and the section and its patterns disappear from the ones that are active on the page.
            </Col>
          </Row>
        </li>
        <li>
          <Row className="mb-3">
            <Col xs={12} md={6} className="tutorial--image">
              <TutorialStep src={step15} alt="Project list page" />
            </Col>
            <Col xs={12} md={6} className="tutorial--description">
              If you want to see all of your projects, by the by, you can click on "Existing Projects" in the menu. From here you can View/Edit/Delete all of the projects that you have added to your browser.
            </Col>
          </Row>
        </li>
      </ul>
    </Container>
  );
};

export default Tutorial;
