import React, { useState, useEffect, useRef } from 'react';
import { Row } from 'react-bootstrap';
import db from '../../db';
import Counter from './Counter';

const PatternCounter = ({ pattern, projectCurrentRow, deactivateSinglePatternCounter, wrapperSetDeactivateSinglePatternCounter, setSuccessMessage }) => {
  const [error, setError] = useState();
  const [currentRepeat, setCurrentRepeat] = useState(pattern.completed_repeats);
  const [active, setActive] = useState(pattern.active);
  const [repeatsLeft, setRepeatsLeft] = useState(pattern.total_repeats - pattern.completed_repeats);
  const firstUpdate = useRef(true);

  const handleDeactivate = (setCounter) => {
    setCurrentRepeat(currentRepeat + 1);
    setCounter(0);

    if (currentRepeat + 1 === pattern.total_repeats && pattern.auto_deactivate) {
      setActive(false);
    }
  }

  useEffect(() => {
    try {
      db.patterns.update(pattern.id, { completed_repeats: currentRepeat, active: active }).then(updated => {
        if (updated) {
          if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
          }

          if (active) setRepeatsLeft(repeatsLeft - 1);

          if (!active) wrapperSetDeactivateSinglePatternCounter(true, pattern.name);
        }
        if (!updated) setError('Something went wrong. Your finished repeat may not have been saved.');
      });
    }
    catch (e) {
      console.log(e);
      setError('Something went wrong. Your finished repeat may not have been saved.');
    }
  }, [currentRepeat, active]);

  return (
    <Row>
      <h5 className="text-center">{pattern.name} Counter</h5>
      {error && (<div className="mb-3 alert alert-danger">{error}</div>)}
      <Counter
        counter_type={"patterns"}
        counter_id={pattern.id}
        current_row={pattern.current_row}
        total_rows={pattern.total_rows}
        projectCurrentRow={projectCurrentRow}
        handleDeactivate={handleDeactivate}
        setSuccessMessage={setSuccessMessage}
      />
      <div className="pattern--repeats text-center">{repeatsLeft} repeats left</div>
    </Row>
  );
};

export default PatternCounter;
