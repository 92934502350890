import React from 'react';
import { ListGroup, Container, Row, Col } from 'react-bootstrap';

const ProjectListItem = ({ project }) => {
  return (
    <ListGroup.Item as="li" key={project.id} className="list--list-item">
      <Container fluid>
        <Row>
          <Col xs={12} md={4} lg={6}>
            <h5>{project.name}</h5>
            <div className="fst-italic">{project.description}</div>
          </Col>
          <Col xs={6} md={2} lg={1}>
            <span className="d-inline d-md-none fw-bold">Current row: </span>{project.current_row}
          </Col>
          <Col xs={6} md={2} lg={1}>
            <span className="d-inline d-md-none fw-bold">Total rows: </span>{project.total_rows}
          </Col>
          <Col xs={12} md={4}>
            <ListGroup horizontal className="project--action-items">
              <ListGroup.Item action href={`/projects/${project.id}`} className="project--action-item">View</ListGroup.Item>
              <ListGroup.Item action href={`/projects/${project.id}/edit`} className="project--action-item">Edit</ListGroup.Item>
              <ListGroup.Item action href={`/projects/${project.id}/delete`} className="project--action-item">Delete</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </ListGroup.Item>
  );
};

export default ProjectListItem;
