import React, { useState, useEffect } from 'react';
import { Container, Form, Button, FloatingLabel, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from "yup";
import db from '../../db';

const PatternForm = ({ isNew, section, pattern }) => {
  const patternSchema = Yup.object().shape({
    section_id: Yup.number().required('Section ID is required.'),
    name: Yup.string().required("Name is required."),
    description: Yup.string("Description must be text."),
    current_row: Yup.number("Current row must be a number.").integer("Current row must be a whole number.").min(0, "Current row must be 0 or greater.").required("Current row is required."),
    total_rows: Yup.number("Total rows must be a number.").integer("Total rows must be a whole number.").min(0, "Total rows must be 0 or greater.").required("Total rows is required."),
    completed_repeats: Yup.number("Completed repeats must be a number.").integer("Completed repeats must be a whole number.").min(0, "Completed repeats must be 0 or greater.").required("Completed repeats is required."),
    total_repeats: Yup.number("Repeats must be a number.").integer("Repeats must be a whole number.").min(0, "Repeats must be 0 or greater.").required("Repeats is required."),
    active: Yup.bool('Active must be a true/false.'),
    auto_deactivate: Yup.bool('Auto-Deactivate must be a true/false.'),
  });

  const [patternValues, setPatternValues] = useState({
    section_id: pattern && pattern.section_id ? pattern.section_id : section.id,
    name: pattern && pattern.name ? pattern.name : '',
    description: pattern && pattern.description ? pattern.description : '',
    current_row: pattern && pattern.current_row ? pattern.current_row : 0,
    total_rows: pattern && pattern.total_rows ? pattern.total_rows : 0,
    completed_repeats: pattern && pattern.completed_repeats ? pattern.completed_repeats : 0,
    total_repeats: pattern && pattern.total_repeats ? pattern.total_repeats : 0,
    active: pattern && pattern.active ? pattern.active : false,
    auto_deactivate: pattern && pattern.auto_deactivate ? pattern.auto_deactivate : false
  });

  const [formStatus, setFormStatus] = useState();

  const [initialRun, setInitialRun] = useState(true);

  let success_message = `${section.name}'s ${patternValues.name} pattern saved!`;
  const danger_message = 'Something went wrong. Your pattern may not have been saved.';

  useEffect(() => {
    const updateDb = async () => {
      try {
        if (isNew) {
          db.patterns.add(patternValues).then(updated => {
            if (updated) setFormStatus({ type: "success", message: success_message, project_id: section.project_id });
            if (!updated) setFormStatus({ type: "danger", message: danger_message });
          });;
        }
        else {
          db.patterns.update(pattern.id, patternValues).then(updated => {
            if (updated) setFormStatus({ type: "success", message: success_message, project_id: section.project_id });
            if (!updated) setFormStatus({ type: "danger", message: danger_message });
          });;
        }
      }
      catch (error) {
        console.log(error);
        setFormStatus({ type: "danger", message: danger_message });
      }
    }

    // Only want to update if not the first update (ie: when things are initially set).
    if (!initialRun) updateDb();
  }, [patternValues, isNew, section, pattern, initialRun]);

  const handleSubmit = async (values, { resetForm }) => {
    let newPatternValues = {
      section_id: patternValues.section_id,
      name: values.name,
      description: values.description,
      current_row: values.current_row,
      total_rows: values.total_rows,
      completed_repeats: values.completed_repeats,
      total_repeats: values.total_repeats,
      active: values.active,
      auto_deactivate: values.auto_deactivate
    };

    success_message = `${section.name}'s ${newPatternValues.name} pattern saved!`;

    setPatternValues(newPatternValues);
    setInitialRun(false);

    if (isNew) resetForm();
  }

  return (
    <Container className="form">
      {formStatus && (
        <Row className="mb-3">
          <Form.Group className={`alert alert-${formStatus.type}`}>
            {formStatus.message} {formStatus.project_id && (<a href={`/projects/${formStatus.project_id}`} className="alert-link">Go to current project.</a>)}
          </Form.Group>
        </Row>
      )}
      <Formik
        initialValues={patternValues}
        validationSchema={patternSchema}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Container className="form--pattern mb-3">
            <h1 className="mb-3 text-center">{isNew && ("New")}{!isNew && (`Edit ${pattern.name}`)} Pattern for {section.name}</h1>
            <Form noValidate onSubmit={formik.handleSubmit} aria-label="pattern-form">
              <Row className="mb-3">
                <Col md={8}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Pattern Name" controlId="name" >
                      <Form.Control
                        type="text"
                        size="lg"
                        placeholder="Pattern name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.name}
                        disabled={formik.isSubmitting}
                        value={formik.values.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.name}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Description" controlId="description" >
                      <Form.Control
                        as="textarea"
                        style={{ height: '100px' }}
                        placeholder="Pattern description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.description}
                        disabled={formik.isSubmitting}
                        value={formik.values.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.description}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Current Row" controlId="current_row" >
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="0"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.current_row}
                        disabled={formik.isSubmitting}
                        value={formik.values.current_row}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.current_row}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Total Rows" controlId="total_rows" >
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="0"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.total_rows}
                        disabled={formik.isSubmitting}
                        value={formik.values.total_rows}
                        description="The total number of rows in each pattern repeat."
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.total_rows}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Completed Repeats" controlId="completed_repeats" >
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="0"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.completed_repeats}
                        disabled={formik.isSubmitting}
                        value={formik.values.completed_repeats}
                        description="The total number of times the pattern has been knit."
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.completed_repeats}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Total Repeats" controlId="total_repeats" >
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="0"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.total_repeats}
                        disabled={formik.isSubmitting}
                        value={formik.values.total_repeats}
                        description="The total number of times the pattern is knit."
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.total_repeats}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      id="active"
                      label="Active"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.active}
                      disabled={formik.isSubmitting}
                      checked={formik.values.active}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.active}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      id="auto_deactivate"
                      label="Auto-Deactivate"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.auto_deactivate}
                      disabled={formik.isSubmitting}
                      checked={formik.values.auto_deactivate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.auto_deactivate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="primary" type="submit" disabled={formik.isSubmitting || formik.errors.length}>
                Save Pattern
              </Button>
            </Form>
          </Container>
        )}
      </Formik>
    </Container>
  );
};

export default PatternForm;
