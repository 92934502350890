import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useLiveQuery } from "dexie-react-hooks";
import db from '../../db';

const ExistingPatternDelete = () => {
  const navigate = useNavigate();

  const [error, setError] = useState();

  const { project_id, section_id, pattern_id } = useParams();
  const section = useLiveQuery(() => db.sections.where("id").equals(parseInt(section_id)).toArray());
  const pattern = useLiveQuery(() => db.patterns.where("id").equals(parseInt(pattern_id)).toArray());

  if (!section) return null;
  if (!pattern) return null;

  const handleClick = async () => {
    try {
      db.patterns.delete(pattern[0].id).then(updated => {
        navigate(`/projects/${project_id}`);
      });
    }
    catch (error) {
      console.log(error);
      setError('Something went wrong. Your pattern may not have been deleted.');
    }
  }

  return (
    <Container as="main" className="main">
      <Container className="confirmation--delete mb-3">
        {error && <div className="mb-3 alert alert-danger">{error}</div>}
        <div className="confirmation--text mb-3">
          <h1>Delete {pattern[0].name}?</h1>
          Are you sure you want to delete your {section[0].name}'s pattern <span class="fw-bold">{pattern[0].name}</span>? This will also delete all associated data like pattern row counts!
        </div>
        <div className="confirmation--actions mb-3">
          <Button variant="primary" onClick={handleClick} className="actions--delete">
            Delete Pattern
          </Button>

          <Link to={`/projects/${project_id}`}>
            <Button variant="secondary" className="actions--cancel">
              Go Back
            </Button>
          </Link>
        </div>
      </Container>
    </Container>
  );
};

export default ExistingPatternDelete;
