import React, { useState, useEffect, useCallback } from 'react';
import { Container, ListGroup, Accordion, Row, Col } from 'react-bootstrap';
import { useLiveQuery } from "dexie-react-hooks";
import db from '../../db';
import Counter from '../Counters/Counter';
import SectionCounter from '../Counters/SectionCounter';
import SectionList from '../SectionList/SectionList';

const Project = ({ project_id, project }) => {
  const [activeSections, setActiveSections] = useState([]);
  const [deactivateSingleCounter, setDeactivateSingleCounter] = useState(false);
  const [deactivateMessage, setDeactivateMessage] = useState();
  const [currentRow, setCurrentRow] = useState(project.current_row);
  const sections = useLiveQuery(() => db.sections.where("project_id").equals(project_id).toArray(), [deactivateSingleCounter, currentRow]);

  const wrapperSetDeactivateSingleCounter = useCallback((val, section_name) => {
    setDeactivateSingleCounter(val);
    setDeactivateMessage(`${section_name} finished.`);
  }, [setDeactivateSingleCounter, setDeactivateMessage]);

  const wrapperSetActiveSections = useCallback(() => {
    let filtered_sections = sections?.filter(section => {
      return section.active;
    }).map(section => {
      return (
        <Col className="section--counter-wrapper" key={"counter-wrapper"}>
          <SectionCounter
            section={section}
            deactivateSingleCounter={deactivateSingleCounter}
            wrapperSetDeactivateSingleCounter={wrapperSetDeactivateSingleCounter}
            projectCurrentRow={currentRow}
            setSuccessMessage={setDeactivateMessage}
          />
        </Col>
      );
    })
    setActiveSections(filtered_sections ? filtered_sections : []);
  }, [setActiveSections, deactivateSingleCounter, wrapperSetDeactivateSingleCounter, sections]);

  useEffect(() => {
    setDeactivateSingleCounter(false)
    wrapperSetActiveSections();
  }, [sections, wrapperSetActiveSections, currentRow]);

  if (!sections) return null;

  if (project.length === 0) return (
    <Container className="project mb-4">
      <div className="mb-3 alert alert-danger">Project {project_id} does not exist.</div>
    </Container>
  );

  return (
    <Container className="project">
      <Container className="project--metadata text-center">
        <h1 className="project--name">{project.name}</h1>
        <div className="project--actions mb-3">
          <ListGroup horizontal className="project--action-items">
            <ListGroup.Item action href={`/projects/${project.id}/sections/new`} className="project--action-item">New Section</ListGroup.Item>
            <ListGroup.Item action href={`/projects/${project.id}/edit`} className="project--action-item">Edit</ListGroup.Item>
            <ListGroup.Item action href={`/projects/${project.id}/delete`} className="project--action-item">Delete</ListGroup.Item>
          </ListGroup>
        </div>
        <div className="project--description fst-italic">{project.description}</div>
      </Container>
      <Container className="project--counters">
        <Row className="project--counter">
          <Counter
            counter_type={"projects"}
            counter_id={project_id}
            current_row={project.current_row}
            total_rows={project.total_rows}
            show_button={true}
            setCurrentProjectRow={setCurrentRow}
            setSuccessMessage={setDeactivateMessage}
          />
        </Row>
        {deactivateMessage && (<div className="mb-3 alert alert-success">{deactivateMessage}</div>)}
        {activeSections.length > 0 && (
          <Row className="section--counters">
            <h2 className="text-center">Section Counters</h2>
            {activeSections}
          </Row>
        )}
      </Container>
      {sections.length > 0 && (
        <Container className="project--sections mb-3">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>See List of Sections and Patterns</Accordion.Header>
              <Accordion.Body>
                <SectionList project_id={project_id} sections={sections} deactivateSingleCounter={deactivateSingleCounter} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      )}
    </Container>
  );
};

export default Project;
