import React, { useState, useEffect } from 'react';
import { Container, Form, Button, FloatingLabel, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from "yup";
import db from '../../db';

const SectionForm = ({ isNew, project, section }) => {
  const sectionSchema = Yup.object().shape({
    project_id: Yup.number().required('Project ID is required.'),
    name: Yup.string().required("Name is required."),
    description: Yup.string("Description must be text."),
    current_row: Yup.number("Current row must be a number.").integer("Current row must be a whole number.").min(0, "Current row must be 0 or greater."),
    total_rows: Yup.number("Total rows must be a number.").integer("Total rows must be a whole number.").min(0, "Total rows must be 0 or greater."),
    active: Yup.bool('Active must be a true/false.'),
    auto_deactivate: Yup.bool('Auto-Deactivate must be a true/false.')
  });

  const [sectionValues, setSectionValues] = useState({
    project_id: section && section.project_id ? section.project_id : project.id,
    name: section && section.name ? section.name : '',
    description: section && section.description ? section.description : '',
    current_row: section && section.current_row ? section.current_row : 0,
    total_rows: section && section.total_rows ? section.total_rows : 0,
    active: section && section.active ? section.active : false,
    auto_deactivate: section && section.auto_deactivate ? section.auto_deactivate : false
  });

  const [formStatus, setFormStatus] = useState();

  const [initialRun, setInitialRun] = useState(true);

  let success_message = `${project.name}'s ${sectionValues.name} section saved!`;
  const danger_message = 'Something went wrong. Your section may not have been saved.';

  useEffect(() => {
    const updateDb = async () => {
      try {
        if (isNew) {
          db.sections.add(sectionValues).then(updated => {
            if (updated) setFormStatus({ type: "success", message: success_message, project_id: project.id });
            if (!updated) setFormStatus({ type: "danger", message: danger_message });
          });;
        }
        else {
          db.sections.update(section.id, sectionValues).then(updated => {
            if (updated) setFormStatus({ type: "success", message: success_message, project_id: project.id });
            if (!updated) setFormStatus({ type: "danger", message: danger_message });
          });;
        }
      }
      catch (error) {
        console.log(error);
        setFormStatus({ type: "danger", message: danger_message });
      }
    }

    // Only want to update if not the first update (ie: when things are initially set).
    if (!initialRun) updateDb();
  }, [sectionValues, isNew, project, section, initialRun]);

  const handleSubmit = async (values, { resetForm }) => {
    let newSectionValues = {
      project_id: sectionValues.project_id,
      name: values.name,
      description: values.description,
      current_row: values.current_row,
      total_rows: values.total_rows,
      active: values.active,
      auto_deactivate: values.auto_deactivate
    };

    success_message = `${project.name}'s ${newSectionValues.name} section saved!`;

    setSectionValues(newSectionValues);
    setInitialRun(false);

    if (isNew) resetForm();
  }

  return (
    <Container className="form">
      {formStatus && (
        <Row className="mb-3">
          <Form.Group className={`mb-3 alert alert-${formStatus.type}`}>
            {formStatus.message}  {formStatus.project_id && (<a href={`/projects/${formStatus.project_id}`} className="alert-link">Go to current project.</a>)}
          </Form.Group>
        </Row>
      )}
      <Formik
        initialValues={sectionValues}
        validationSchema={sectionSchema}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Container className="form--section mb-3">
            <h1 className="mb-3 text-center">{isNew && ("New")}{!isNew && (`Edit ${section.name}`)} Section for {project.name}</h1>
            <Form noValidate onSubmit={formik.handleSubmit} aria-label="section-form">
              <Row className="mb-3">
                <Col md={8}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Section Name" controlId="name" >
                      <Form.Control
                        type="text"
                        size="lg"
                        placeholder="Section name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.name}
                        disabled={formik.isSubmitting}
                        value={formik.values.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.name}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Description" controlId="description" >
                      <Form.Control
                        as="textarea"
                        style={{ height: '100px' }}
                        placeholder="Section description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.description}
                        disabled={formik.isSubmitting}
                        value={formik.values.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.description}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Current Row" controlId="current_row" >
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="0"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.current_row}
                        disabled={formik.isSubmitting}
                        value={formik.values.current_row}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.current_row}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Total Rows" controlId="total_rows" >
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="0"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.total_rows}
                        disabled={formik.isSubmitting}
                        value={formik.values.total_rows}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.total_rows}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      id="active"
                      label="Active"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.active}
                      disabled={formik.isSubmitting}
                      checked={formik.values.active}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.active}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      id="auto_deactivate"
                      label="Auto-Deactivate"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.auto_deactivate}
                      disabled={formik.isSubmitting}
                      checked={formik.values.auto_deactivate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.auto_deactivate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="primary" type="submit" disabled={formik.isSubmitting || formik.errors.length}>
                Save Section
              </Button>
            </Form>
          </Container>
        )}
      </Formik>
    </Container>
  );
};

export default SectionForm;
