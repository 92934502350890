import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLiveQuery } from "dexie-react-hooks";
import db from '../../db';
import Counter from './Counter';
import PatternCounter from './PatternCounter';

const SectionCounter = ({ section, projectCurrentRow, deactivateSingleCounter, wrapperSetDeactivateSingleCounter, setSuccessMessage }) => {
  const [activePatterns, setActivePatterns] = useState([]);
  const [deactivateSinglePatternCounter, setDeactivateSinglePatternCounter] = useState(false);
  const [deactivatePatternMessage, setDeactivatePatternMessage] = useState();
  const [error, setError] = useState();
  const [active, setActive] = useState(section.active);
  const patterns = useLiveQuery(() => db.patterns.where("section_id").equals(section.id).toArray(), [deactivateSinglePatternCounter, projectCurrentRow]);
  const firstUpdate = useRef(true);

  const wrapperSetDeactivateSinglePatternCounter = useCallback((val, pattern_name) => {
    setDeactivateSinglePatternCounter(val);
    setDeactivatePatternMessage(`${pattern_name} finished.`);
  }, [setDeactivateSinglePatternCounter, setDeactivatePatternMessage]);

  const wrapperSetActivePatterns = useCallback(() => {
    let filtered_patterns = patterns?.map(pattern => {
      if (pattern.active) return (
        <Col key={pattern.id} className="pattern--counter">
          <PatternCounter
            pattern={pattern}
            projectCurrentRow={projectCurrentRow}
            deactivateSinglePatternCounter={deactivateSinglePatternCounter}
            wrapperSetDeactivateSinglePatternCounter={wrapperSetDeactivateSinglePatternCounter}
            setSuccessMessage={setDeactivatePatternMessage}
          />
        </Col>
      )
      return null;
    })
    setActivePatterns(filtered_patterns ? filtered_patterns : []);
  }, [setActivePatterns, deactivateSinglePatternCounter, wrapperSetDeactivateSinglePatternCounter, patterns]);

  useEffect(() => {
    setDeactivateSinglePatternCounter(false)
    wrapperSetActivePatterns();
  }, [patterns, wrapperSetActivePatterns]);

  const handleDeactivate = (setCounter) => {
    if (section.auto_deactivate) {
      setActive(false);
    }
  }

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    try {
      db.sections.update(section.id, { active: active }).then(updated => {
        if (updated) {
          wrapperSetDeactivateSingleCounter(true, section.name);
        }
        if (!updated) setError('Something went wrong. Your finished section may not have been deactivated.');
      });
    }
    catch (e) {
      console.log(e);
      setError('Something went wrong. Your finished section may not have been deactivated.');
    }
  }, [active]);

  if (!patterns) return null;

  return (
    <Row>
      <Col className="section--counter">
        <h3 className="text-center">{section.name} Counter</h3>
        {error && (<div className="mb-3 alert alert-danger">{error}</div>)}
        {deactivatePatternMessage && (<div className="mb-3 alert alert-success">{deactivatePatternMessage}</div>)}
        <Counter
          counter_type={"sections"}
          counter_id={section.id}
          current_row={section.current_row}
          total_rows={section.total_rows}
          projectCurrentRow={projectCurrentRow}
          handleDeactivate={handleDeactivate}
          setSuccessMessage={setSuccessMessage}
        />
      </Col>
      {activePatterns.length > 0 && (
        <Col className="pattern--counters mb-3">
          <h4 className="text-center">Pattern Counters</h4>
          <Row>{activePatterns}</Row>
        </Col>
      )}
    </Row>
  );
};

export default SectionCounter;
